export default {
    nav: {
        dashboard: 'Dashboard',
        balance: 'Balance',
        reports: 'Reports',
        documents: 'Documents',
        vending: 'Vendings',
        couriers: 'Couriers',
        materials: 'Materials',
        banners: 'Banners',
    },
    header: {
        company: 'Company',
        user: 'User',
    },
    mobileMenu: {
        company: 'Company settings',
        user: 'My profile',
    },
    footer: {
        contractOffer: 'User agreement',
    },
    buttons: {
        logout: 'Sign out',
    },
};
