export default {
    title: 'Восстановление пароля',
    form: {
        fields: {
            phone: {
                label: 'Телефон',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            verificationCode: {
                label: 'Код из sms',
                validations: {
                    require: 'Поле обязательно для заполнения',
                },
            },
            password: {
                label: 'Новый пароль',
                validations: {
                    require: 'Поле обязательно для заполнения',
                    repeat: 'Пароли должны совпадать',
                },
            },
            repeatPassword: {
                label: 'Повторите новый пароль',
                validations: {
                    require: 'Поле обязательно для заполнения',
                    repeat: 'Пароли должны совпадать',
                },
            },
        },
        submitButton: 'Войти',
    },
    labels: {
        phoneStep: 'Введите номер телефона, и мы отправим вам SMS с кодом для сброса пароля',
        verificationStep:
            'Мы отправили SMS с кодом на номер {{phoneNumber}} Введите его, чтобы сбросить пароль',
        createPasswordStep: 'Отлично :) Теперь введите новый пароль',
    },
    buttons: {
        sendCode: 'Отправить',
        createPassword: 'Сменить пароль',
        repeatVerification: 'Я не получил код :(',
    },
    messages: {
        successResetPassword: `Пароль успешно изменён`,
        requestResetPassword: `Код повторно отправлен на номер {{phoneNumber}}`,
    },
};
