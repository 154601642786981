import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import { initConfig } from './config';
import * as serviceWorker from './serviceWorker';

import './index.css';

let timer: NodeJS.Timeout;

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://6e97f91f68a74ce2ba1ddb69de594043@o567362.ingest.sentry.io/5798456',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.5,
        environment: process.env.NODE_ENV,
        release: '0.1.0',
    });
}

if (process.env.NODE_ENV === 'production') console.log = () => {};

//@ts-ignore
if (window.env) {
    initConfig();
    ReactDOM.render(<App />, document.getElementById('root'));
} else {
    timer = setInterval(() => {
        //@ts-ignore
        if (window.env) {
            clearInterval(timer);
            initConfig();
            ReactDOM.render(<App />, document.getElementById('root'));
        }
    }, 200);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
