export default {
    title: {
        banners: 'Banners',
        newBanner: 'New banner',
        editBanner: 'Edit banner',
        editBannerShort: 'Edit banner',
        editStoryShort: 'Edit story',
    },
    types: {
        ClickBanner: 'Advertising banner',
        Story: 'Story',
        InfoBanner: 'Information banner',
        ProductSetBanner: 'Set of products',
    },
    form: {
        label: {
            type: 'Banner type',
            cities: 'Cities',
            position: 'Position',
            bannerPreview: 'Banner preview',
            storyPreview: 'Story preview',
            banner: 'Banner',
            story: 'Story',
            storySlide: 'Story slide',
            link: 'Link',
            screenInApp: 'Screen in the mobile app',
            productSetNumber: 'Product set number',
            buttonCaption: 'Button caption',
            duration: 'Duration',
            description: 'Description',
            pageMobile: 'Page in app',
            linkType: 'Link type',
            targetTab: 'Tab',
            linkTypes: {
                external: 'External link',
                deeplink: 'Link to a screen in the mobile app',
                notSpecified: 'Link type not specified',
            },
            appScreens: {
                catalog: 'Catalog',
                about: 'About',
                invite: 'Invite a friend',
                chat: 'Chat',
                contact: 'Contact',
                mainProfile: 'Main profile',
                myProfile: 'My profile',
                coinsHistory: 'Coins history',
            },
        },
        button: {
            addBanner: 'Add banner',
            addStory: 'Add story',
            addSlideStory: 'Add slide',
            deleteBanner: 'Delete banner',
            deleteStory: 'Delete story',
            deleteSlideStory: 'Delete slide',
            saveChanges: 'Save changes',
        },
        deleteConfirmation: {
            titleBanner: 'Are you sure you want to delete the banner?',
            titleStory: 'Are you sure you want to delete the story?',
            titleSlideStory: 'Are you sure you want to delete the slide?',
            content: 'You cannot undo this action',
            ok: 'Yes, delete',
            cancel: 'Cancel',
        },
        error: {
            required: 'Required field',
            notSpecified: 'Not specified for partner',
        },
        help: {
            noLonger: 'No longer than {{DELAY_MAX_VALUE}} sec.',
            symbolsLeft: 'Symbols left',
            uploadPreview: `Upload image\n\nPreview size is arbitrary, aspect ratio is 14:15\nFile size should not exceed 2 megabytes`,
            uploadBanner: `Upload image\n\nPreview size is arbitrary, aspect ratio is 2:1\nFile size should not exceed 2 megabytes`,
            uploadStorySlide: `Upload image\n\nSlide size:\n\nHeight: 1920 px\nWidth: 1080 px\n\nFile size\nshould not exceed\n2 megabytes`,
        },
        message: {
            bannerUpdated: 'The banner has been updated',
            storyUpdated: 'The story has been updated',
            storySlideUpdated: 'The slide has been updated',
            bannerDeleted: 'The banner has been deleted',
            storyDeleted: 'The story has been deleted',
            storySlideDeleted: 'The slide has been deleted',
            clickBannerAdded: 'Advertising banner has been added',
            storyAdded: 'Story has been added',
            storySlideAdded: 'Slide has been added',
            infoBannerAdded: 'Information banner has been added',
            productSetBannerAdded: 'Set of products has been added',
        },
        suffix: {
            sec: 'sec.',
            optional: '(optional)',
        },
    },
};
