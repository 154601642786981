
export default {
    title: 'Password recovery',
    form: {
        fields: {
            phone: {
                label: "Phone",
                validations: {
                    require: "this field is required",
                }
            },
            verificationCode: {
                label: "Code from sms",
                validations: {
                    require: "this field is required",
                }
            },
            password: {
                label: "New password",
                validations: {
                    require: "this field is required",
                    repeat: "Passwords must match"
                }
            },
            repeatPassword: {
                label: "Repeat the new password",
                validations: {
                    require: "this field is required",
                    repeat: "Passwords must match"
                }
            }
        },
        submitButton: "Войти"
    },
    labels: {
        phoneStep: "Enter your phone number and we will send you an SMS with a code to reset your password",
        verificationStep: "We sent an SMS with a code to {{phoneNumber}} Enter it to reset your password",
        createPasswordStep: "Great :) Now enter your new password"
    },
    buttons: {
        sendCode: "Send",
        createPassword: "Change password",
        repeatVerification: "I didn't get the code :("
    },
    messages: {
        successResetPassword:'Password changed successfully',
        requestResetPassword: `Code re-sent to {{phoneNumber}}`
    }
}