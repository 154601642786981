import React from 'react';

import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ConfigProvider } from 'antd';
import { useUrlSearchParams } from 'use-url-search-params';
import 'url-search-params-polyfill';
import { lazy } from '@loadable/component';
import * as Sentry from '@sentry/react';

import AuthService from './aqua-delivery-web-client-ui/AuthService';
import YandexAnalytics from './aqua-delivery-web-client-ui/YandexAnalytics';
import I18n, { I18nSetConfig, I18nContext } from './aqua-delivery-web-client-ui/i18n';
import SuspenseWithLoader from './aqua-delivery-web-client-ui/components/loader/SuspenseWithLoader';
import LoaderContainer from './aqua-delivery-web-client-ui/components/loader/LoaderContainer';
import ErrorPage from './aqua-delivery-web-client-ui/components/static-pages/ErrorPage';
import EmptyPanel from './aqua-delivery-web-client-ui/components/EmptyPanel';
import Loader from './aqua-delivery-web-client-ui/components/loader/Loader';
import useDocumentLanguage from './aqua-delivery-web-client-ui/hooks/useDocumentLanguage';
import Localization from './aqua-delivery-web-client-ui/localization/Localization';
import localizationConfig from './aqua-delivery-web-client-ui/localization/localizationConfig';
import translations from './translations';
import useAntdLocale from './hooks/useAntdLocale';
import { config } from './config';

import './aqua-delivery-web-client-ui/assets/styles/common.less';

const history = createBrowserHistory();

const LoginAsync = lazy(() => import('./routes/login'));
const PartnerAsync = lazy(() => import('./routes/partner-cabinet'));
const PreRegistrationAsync = lazy(() => import('./routes/begin-registration'));
const RegistrationAsync = lazy(() => import('./routes/registration'));
const ConfirmRegistrationAsync = lazy(() => import('./routes/confirm-registration'));
const FinishRegistrationAsync = lazy(() => import('./routes/registration/FinishRegistration'));
const RecoveryPasswordAsync = lazy(() => import('./routes/recoveryPassword/RecoveryPassword'));
const TechnicalWorkPageAsync = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/TechnicalWorkPage'),
);
const Page404Async = lazy(
    () => import('./aqua-delivery-web-client-ui/components/static-pages/Page404'),
);

I18nSetConfig({ translations });

const ymOptions = { clickmap: true, webvisor: true };

const ymInitializerOptions = { version: '2' };

const App: React.FC = () => {
    React.useEffect(() => {
        window.document.title = 'Aqua Delivery';
    }, []);

    const [locale, setLocale] = React.useState(I18n.locale);

    const { antdLocale } = useAntdLocale();

    const [appParams] = useUrlSearchParams({}, { isAppUpdating: Boolean });

    const isAppUpdating = config.isAppUpdating && appParams.isAppUpdating !== false;

    const { updateDocumentLocale } = useDocumentLanguage();

    React.useEffect(() => {
        updateDocumentLocale(locale);
    }, [locale, updateDocumentLocale]);

    const redirectRulesWhenUserLoggedIn = (path: string) => {
        const regexpMap: { regexp: RegExp; component: React.ReactNode }[] = [
            { regexp: /\/login/, component: <Redirect from="/login" to="/" /> },
            { regexp: /\/registration/, component: <Redirect from="/registration" to="/" /> },
        ];

        const redirectComponent = regexpMap.find(mapItem => mapItem.regexp.test(path));

        return redirectComponent?.component;
    };

    const authToken = AuthService.getToken();

    const userVisitParams = React.useMemo(() => {
        if (!authToken) return undefined;

        const userInfo = AuthService.decodeToken(authToken);

        return {
            UserID: userInfo.uuid,
        };
    }, [authToken]);

    return (
        <I18nContext.Provider
            value={{
                locale,
                setLocale: locale => {
                    I18nSetConfig({ locale });
                    setLocale(locale);
                },
            }}
        >
            <YandexAnalytics
                accounts={config.metrics.ym}
                ymOptions={ymOptions}
                visitParams={userVisitParams}
                ymInitializerOptions={ymInitializerOptions}
            >
                <LoaderContainer height={window.innerHeight}>
                    <SuspenseWithLoader fallback={<Loader loading={true} />}>
                        {isAppUpdating ? (
                            <TechnicalWorkPageAsync />
                        ) : (
                            <ConfigProvider locale={antdLocale} renderEmpty={() => <EmptyPanel />}>
                                <Localization.Provider value={localizationConfig}>
                                    <Router history={history}>
                                        <Sentry.ErrorBoundary fallback={ErrorPage}>
                                            <Switch>
                                                <Route
                                                    history={history}
                                                    path="/login/:token/:refreshToken?"
                                                    component={LoginAsync}
                                                    exact
                                                />
                                                {AuthService.isLoggedIn() &&
                                                    redirectRulesWhenUserLoggedIn(
                                                        history.location.pathname,
                                                    )}
                                                <Route
                                                    history={history}
                                                    path="/begin-registration"
                                                    component={PreRegistrationAsync}
                                                    exact
                                                />
                                                <Route
                                                    history={history}
                                                    path="/registration"
                                                    component={RegistrationAsync}
                                                    exact
                                                />
                                                <Route
                                                    history={history}
                                                    path="/register/finish"
                                                    component={FinishRegistrationAsync}
                                                    exact
                                                />
                                                <Route
                                                    history={history}
                                                    path="/register/confirm"
                                                    component={ConfirmRegistrationAsync}
                                                    exact
                                                />
                                                <Route
                                                    history={history}
                                                    path="/login"
                                                    component={LoginAsync}
                                                    exact
                                                />
                                                <Route
                                                    history={history}
                                                    path="/recovery-password"
                                                    component={RecoveryPasswordAsync}
                                                    exact
                                                />
                                                <Route
                                                    history={history}
                                                    path="/"
                                                    component={PartnerAsync}
                                                />
                                                <Route component={Page404Async} />
                                            </Switch>
                                        </Sentry.ErrorBoundary>
                                    </Router>
                                </Localization.Provider>
                            </ConfigProvider>
                        )}
                    </SuspenseWithLoader>
                </LoaderContainer>
            </YandexAnalytics>
        </I18nContext.Provider>
    );
};

export default App;
