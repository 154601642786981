import * as React from "react";
import { LoaderContext } from "./LoaderContext";


function makeid()
{
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz";

    for( var i=0; i < 8; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

const Loader: React.FC<{loading?: boolean}> = ({ loading }) => {
    const [loaderId] = React.useState(makeid())
    const loader = React.useContext(LoaderContext)

    React.useEffect(()=>{
        loader.setLoaderState(loaderId, !!loading)
        return ()=>{
            loader.setLoaderState(loaderId, false)
        }
    },[loading, loaderId])
    

    return null
}

export default Loader;